import { mapActions, mapState } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import { showSnackBar } from "@/helpers/globalHelpers";
export default {
  name: "CreatePlanType",
  components: {
  },
  data() {
    return {
      dynamicFields: [],
      headersFieldDinamic: [
        { text: "#", value: "index", sortable: false },
        { text: "Valor", value: "name" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      panel: [0], 
      e1: 1,
      isLoadingSavePlanType:false,
      isLoadingProfile: false,
      isLoadingService: false,
      profiles: [],
      profilesSelected: [],
      fieldValues: {},
      selectedServices: [],
      headers: [
        { text: "Nombre de perfil", sortable: false, value: "name" },
        { text: "Acciones", sortable: false, value: "action", align: "end" },
      ],
      fieldHeaders: [
        { text: "Nombre del Campo", value: "field_name", sortable: false },
        { text: "Tipo de Dato", value: "data_type", sortable: false },
        { text: "Requerido", value: "is_required", sortable: false },
        { text: "Acciones", value: "action", sortable: false, align: "end" },
      ],
      formPlanType: {
        type_name: null,
        criteria_name: null,
        flag_action_plan_name: true,
        flag_responsibles: true,
        flag_service_selection: true,
        flag_notify_users: true,
        flag_multiple_dates: true,
        flag_required_additional_fields:false,
        fields: [],
        profiles: [],
        field_groups: []
      },
      field: {
        field_name: null,
        data_type: "TEXTO",
        is_required: false,
      },
      requiredRule: (value) => !!value || "Este campo es requerido",
      optionalRule: () => true,
    };
  },

  watch: {
    'formPlanType.flag_required_additional_fields'(newVal) {
      if (newVal) {
        this.panel.push(1);
      } else {
        this.panel = [0];
        this.dynamicFields = [];
      }
    },
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Tipos de plan de acción",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ListPlanType",
          },
        },
        {
          text: "Crear tipo de plan de acción",
          link: true,
          exact: true,
          disabled: true,
        },
      ];
    },

    ...mapState('security', ['costCenters']),

    filteredProfiles() {
      return this.profiles.filter(
        (profile) =>
          !this.formPlanType.profiles.some(
            (selectedProfile) => selectedProfile.id === profile.id
          )
      );
    },

    isStepTwoNextButtonEnabled() {
      return !!this.formPlanType.type_name && !!this.formPlanType.criteria_name;
    },

    isAddFieldButtonEnabled() {
      return !!this.field.field_name && !!this.field.data_type;
    },

    isStepThreeNextButtonEnabled() {
      return this.formPlanType.fields.length > 0;
    },

    dynamicFieldGroupHeaders() {
      const fieldHeaders = this.formPlanType.fields.map(field => ({
        text: field.field_name,
        value: field.field_name,
        sortable: false
      }));
      return [
        ...fieldHeaders,
        { text: 'Servicios', value: 'servicios', sortable: false },
        { text: 'Acciones', value: 'action', sortable: false, align: 'end' }
      ];
    }
  },
  created() {
    this.getProfilesData();
    this.getCostCenters();
  },
  methods: {
    ...mapActions('security', ['getProfiles', 'getCostCenter']),
    ...mapActions('plan_type', ['createPlanType']),

    async getProfilesData() {
      this.isLoadingProfile = true;
      const { error, response } = await this.getProfiles({ app_id: process.env.VUE_APP_ID })
      if (error) showError(error);
      this.profiles = response;
      this.isLoadingProfile = false;
    },

    async getCostCenters() {
      this.isLoadingService = true;
      const { error } = await this.getCostCenter();
      if (error) showError(error);
      this.isLoadingService = false;
    },

    addProfilesToTable() {
      const uniqueProfiles = this.profilesSelected
        .filter(
          (selectedId) =>
            !this.formPlanType.profiles.some((profile) => profile.id === selectedId)
        )
        .map((id) => this.profiles.find((profile) => profile.id === id));

      this.formPlanType.profiles.push(...uniqueProfiles);
      this.profilesSelected = [];
    },

    removeProfile(profileId) {
      this.formPlanType.profiles = this.formPlanType.profiles.filter(
        (profile) => profile.id !== profileId
      );
    },

    async addField() {
      if (
        this.formPlanType.fields.some(
          (field) => field.field_name.trim() === this.field.field_name.trim()
        )
      ) {
        showSnackBar("El campo ya está agregado.", "warning");
        return;
      }

      if (this.formPlanType.field_groups.length > 0) {
        this.$swal({
          text: "Se eliminarán todas las agrupaciones existentes al agregar una nueva cabecera. ¿Deseas continuar?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0039a6",
          cancelButtonColor: "grey",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sí, eliminar agrupaciones",
        }).then((result) => {
          if (result.isConfirmed) {
            this.formPlanType.field_groups = [];
            showSnackBar("Las agrupaciones han sido eliminadas.", "info");

            this.formPlanType.fields.push({ ...this.field });
            this.field = { field_name: null, data_type: "TEXTO", is_required: false };
            showSnackBar("Cabecera agregada exitosamente.", "success");
          }
        });
      } else {
        this.formPlanType.fields.push({ ...this.field });
        this.field = { field_name: null, data_type: "TEXTO", is_required: false };
        showSnackBar("Cabecera agregada exitosamente.", "success");
      }
    },

    removeField(fieldName) {
      this.formPlanType.fields = this.formPlanType.fields.filter(
        (field) => field.field_name !== fieldName
      );
    },

    addFieldGroup() {
      const missingField = this.formPlanType.fields.find(
        (field) => field.is_required && !this.fieldValues[field.field_name]?.trim()
      );

      if (missingField) {
        showSnackBar(`El campo "${missingField.field_name}" es requerido.`, "warning");
        return;
      }

      const groupedFields = this.formPlanType.fields.map((field) => ({
        field_name: field.field_name,
        value: this.fieldValues[field.field_name] || null,
      }));

      this.formPlanType.field_groups.push({
        service: [...this.selectedServices],
        grouped_fields: groupedFields,
      });

      this.fieldValues = {};
      this.selectedServices = [];

      this.$nextTick(() => {
        this.$refs.fields.forEach((field) => field.resetValidation());
      });

      showSnackBar("Grupo agregado exitosamente.", "success");
    },

    removeFieldGroup(group) {
      this.formPlanType.field_groups = this.formPlanType.field_groups.filter(
        (g) => g !== group
      );
    },

    async savePlanType() {
      if (!this.formPlanType.field_groups.length) {
        showSnackBar(
          "Debe agregar al menos una agrupación para guardar el tipo de plan.",
          "warning"
        );
        return;
      }

    
      const payload = {
        type_name: this.formPlanType.type_name,
        criteria_name: this.formPlanType.criteria_name,
        flag_action_plan_name: this.formPlanType.flag_action_plan_name,
        flag_responsibles: this.formPlanType.flag_responsibles,
        flag_service_selection: this.formPlanType.flag_service_selection,
        flag_notify_users: this.formPlanType.flag_notify_users,
        flag_multiple_dates: this.formPlanType.flag_multiple_dates,
        profiles: this.formPlanType.profiles.map((profile) => profile.id),
        flag_required_additional_fields: this.formPlanType.flag_required_additional_fields,
        fields: this.formPlanType.fields.map((field) => ({
          field_name: field.field_name,
          is_required: field.is_required,
          data_type: field.data_type,
        })),
        field_groups: this.formPlanType.field_groups.map((group) => ({
          service: group.service,
          grouped_fields: group.grouped_fields.map((field) => ({
            field_name: field.field_name,
            value: field.value,
          })),
        })),
        plan_type_fields: this.dynamicFields.map((field) => ({
          field_name: field.field_name,
          field_type: field.type,
          listValues: field.type === "LISTA DESPLEGABLE" ? field.listValues : null,
        })),
      };

    
      this.$swal({
        text: "¿Estás seguro de guardar este tipo de plan?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0039a6",
        cancelButtonColor: "grey",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, guardar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoadingSavePlanType = true;
    
          const { ok, error } = await this.createPlanType(payload);
    
          if (ok) {
    
            showSnackBar("Tipo de plan guardado correctamente.", "success");
    
            this.$router.push({ name: "ListPlanType" });
          } else {
            let errorMsm = error?.data?.message;
            errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
            showSnackBar(errorMsm, "error");
          }
    
          this.isLoadingSavePlanType = false;
        }
      });
    },

    addFieldDinamic() {
      this.dynamicFields.push({
        type: "TEXTO LIBRE",
        name: "",
        newListItem: "",
        listValues: [],
      });
    },
    removeFieldDinamic(index) {
      this.dynamicFields.splice(index, 1);
    },
    addListItemDinamic(fieldIndex) {
      let field = this.dynamicFields[fieldIndex];
      if (field.newListItem.trim() !== "") {
        field.listValues.push({ name: field.newListItem.trim() });
        field.newListItem = "";
      }
    },
    removeListItemDinamic(fieldIndex, itemIndex) {
      this.dynamicFields[fieldIndex].listValues.splice(itemIndex, 1);
    },
  },

  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
};